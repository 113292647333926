* {
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

html {
  height: 99%;
}

body {
  font-size: 16px;
  height: inherit;
  background-color: #eee;
}

table {
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  margin: 30px 0 30px;

  tr:hover {
    background-color: rgba(131, 175, 216, 0.15);
    cursor: pointer;
  }

  thead tr:hover {
    background-color: white;    
  }
  
  th, td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  th:nth-child(1) {
    padding-left: 1rem;
  }
}

.paginator {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.5rem;
}

.pagelayout-container {
  top: 44px;
  height: calc(100vh - 108px) !important;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  .secondary-navigation {
    position:fixed;
    width: 100%;
    height: auto;
    background-color:rgb(241, 241, 241);
    border-bottom: thin solid rgba(148, 148, 148, 0.171);
    padding: 0.25rem;

    .container {
      display: flex;
    }
  
    .push-right {
      justify-content: flex-end;
    }
  }


  .pagelayout-content {
    padding-top: 3.75rem;
    height: 100%;
  }

  .pagelayout-noheader-content {
    padding-top: 1.75rem;
  }
}

.container {
  color:rgb(41, 41, 41);
  height: 100%;
}

.copyright {
  font-size: 0.75rem;
  color:rgb(63, 63, 63)
}
