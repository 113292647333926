.navbar-logo {
  padding-top: 15px;
  width: 100px;
  height: auto;
}

.nav-item {
  font-size: 1.25rem;
}

.bg-registration {
  background-color: #288C01;
}

.navbg-enter {
  opacity: 0;
  transform: scale(0.9);
}

.navbg-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
}

.navbg-exit {
  opacity: 1;
}

.navbg-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}